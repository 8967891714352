import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid,
  Image
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="Webentwicklung in Köln / Bonn - Relaunch, WordPress, HTML-Mails"
      description="► Webentwicklung mit Blick über den Code hinaus: ✓ Website-Relaunch mit WordPress ✓ HTML-Mails ✓ Hugo ✓ Gatsby JS ► Webentwicklung anfragen"
    />
  	<Container>
  		<Container.Inner>
  			<Content>Webentwicklung</Content>
        <Title as="h1">Webentwicklung mit Blick über den Code hinaus</Title>
        <Content big>
          <p>Als verlässlicher Partner code ich nicht nur Webseiten, HTML-Mails oder webbasierte Tools, sondern möchte, das Sie mit dem Ergebnis erfolgreich sind.</p>
          <Button as={Link} primary="true" to="/kontakt/">Webentwicklung anfragen</Button>
        </Content>
		  </Container.Inner>
	  </Container>
    <Container>
      <Container.Inner>
        <Grid className="nb5 flex-wrap-l tc">
          <Grid.Col className="mb5 w-third-l">
            <Image as={Img} className="mb3" fixed={data.wordpressLogo.childImageSharp.fixed} />
            <Title smaller>Website-Relaunch mit WordPress</Title>
            <Content>
              <p>Wordpress-Entwicklung ohne gekaufte Themes und den übermäßigen Einsatz von Plugins. Dafür responsive und schnell. Auf Wunsch Konzept und Umsetzung aus einer Hand.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Image as={Img} className="mb3" fixed={data.mjmlLogo.childImageSharp.fixed} />
            <Title smaller>HTML-Mails</Title>
            <Content>
              <p>Wenn Sie über E-Mail-Marketing verkaufen, sollten Ihre HTML-Mails möglichst lesbar in allen Clients dargestellt werden, damit Sie keinen Umsatz verschenken. Und für Mobilgeräte optimiert sein.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Image as={Img} className="mb3" fixed={data.html5Logo.childImageSharp.fixed} />
            <Title smaller>Statische Webseiten</Title>
            <Content>
              <p>Erstellung von statischen Webseiten wie Landingpages oder Microsites auf Basis von HTML5, CSS/SASS und Javascript/ES6. Von Anfang an Pagespeed-optimiert.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Image as={Img} className="mb3" fixed={data.hugoLogo.childImageSharp.fixed} />
            <Title smaller>Hugo</Title>
            <Content>
              <p>Mit dem Static Site Generator Hugo lassen sich moderne statische Webseiten, Microsites oder Landingpages erstellen. Diese Webseiten sind schneller und sicherer als herkömmliche CMS-basierte Webseiten.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Image as={Img} className="mb3" fixed={data.gatsbyLogo.childImageSharp.fixed} />
            <Title smaller>Gatsby</Title>
            <Content>
              <p>Der React-basierte Static Site Generator Gatsby ermöglicht die Verzahnung von statischen und dynamischen Inhalten. Sowie die Verwendung verschiedener Content-Management-Systeme (CMS) wie WordPress.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Image as={Img} className="mb3" fixed={data.reactLogo.childImageSharp.fixed} />
            <Title smaller>Webbasierte Tools</Title>
            <Content>
              <p>Auf Basis von React lassen sich webbasierte Tools erstellen, die Sie auf Ihrer Webseite oder Landingpage verwenden können. Zum Beispiel einen Kostenrechner.</p>
            </Content>
          </Grid.Col>
        </Grid>
      </Container.Inner>
    </Container>
    <Container>
      <Container.Inner>
        <Quote {...data.allTestimonialsYaml.edges.find(el => el.node.name === 'dakonix').node} />
      </Container.Inner>
    </Container>
    <Contact>
      <Title center>Mehr als nur einfach Code, sondern professionelle Webseiten, HTML-Mails oder Tools</Title>
      <Content big center>
        <p>Lassen Sie uns gemeinsam Ihr nächstes großes oder kleines Projekt realisieren und zum Erfolg führen.</p>
        <Button as={Link} primary="true" to="/kontakt/">Webentwicklung anfragen</Button>
      </Content>
    </Contact>
  </Layout>
)}

export const query = graphql`
  query {
    allTestimonialsYaml(filter: {name: {in: [ "dakonix"]}}) {
      edges {
        node {
          name
          children: content
          source
          image {
            ...rectangleImage
          }
          logo {
            ...logo
          }
        }
      }
    }
    wordpressLogo: file(relativePath: { eq: "wordpress.png" }) {
      ...logoFixedBig
    }
    hugoLogo: file(relativePath: { eq: "hugo.png" }) {
      ...logoFixedBig
    }
    gatsbyLogo: file(relativePath: { eq: "gatsby.png" }) {
      ...logoFixedBig
    }
    mjmlLogo: file(relativePath: { eq: "mjml.png" }) {
      ...logoFixedBig
    }
    html5Logo: file(relativePath: { eq: "html5.png" }) {
      ...logoFixedBig
    }
    reactLogo: file(relativePath: { eq: "react.png" }) {
      ...logoFixedBig
    }
  }
`
